body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  max-width: 300px;
}

span {
  color: #a5080d;
}

#footer {
  bottom: 0;
  position: fixed;
  width: 100%;
}

#root {
  height: 100vh !important;
}

.iconButton {
  width: 46px;
  height: 46px;
}

.googleImage {
  width: 46px;
  height: 46px;
}

.nameCardContainer {
  min-height: 84px;
}
